import $request from "@/https/middleware";
// const baseURL = "https://middleware-general.hycatrade.com/"

const middleware = {
  // stake
  async stake(formData) {
    return $request.post("stake", formData).then((response) => response.data);
  },

  // Swap
  async swap(formData) {
    return $request
      .post("swap/user-wallet-balance", formData)
      .then((response) => response.data);
  },

  // Swap Rate
  async getSwapRate(payload) {
    return $request
      .get(`swap/conversion-rate`, {
        params: payload,
      })
      .then((response) => response.data);
  },

  async completeTransfer(formData) {
    return $request
      .post(`/custom/transfer-with-fees`, formData)
      .then((response) => response.data);
  },

  // Referral Refresh
  async refreshReferral(payload) {
    return $request
      .post(
        `custom/hycatrade/trigger/on-user-frontend-referral-refresh`,
        payload
      )
      .then((response) => response.data);
  },

  // Dashboard Refresh
  async refreshDashboard(payload) {
    return $request
      .post(
        `custom/hycatrade/trigger/on-user-frontend-dashboard-refresh`,
        payload
      )
      .then((response) => response.data);
  },

  // Authorize and Lock User Balance 
  
  async authorizeLock(payload) {
    return $request
      .post(
        `custom/swap-and-lock-user-balance`,
        payload
      )
      .then((response) => response.data);
  },
};

export default middleware;
